







































import { get_custom_near_tech } from "@/api";
import { customNearTechType } from "@/api/api.types";
import { time_format } from "@/shared/utils";
import { Notif } from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";

type positionType =
  customNearTechType["data"]["_embedded"]["nearestTechnician"][0] & {
    position: { lat: number; lng: number };
  };
@Component({ name: "nearby-tech" })
export default class NearbyTech extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  @Prop({ required: true }) readonly building!: {
    name: string;
    position: { lat: number; lng: number };
  };

  /* Variables */

  data = [] as positionType[];

  infoWindow = {
    position: { lat: 0, lng: 0 },
    name: "",
    distance: "",
    phone: "",
    mobile: "",
    lastOnline: "",
    id: "",
    IsOpen: false,
    options: { pixelOffset: { width: 0, height: -35 } },
  };

  /* Computed */

  /* Methods */

  async fetchData() {
    try {
      const resp = await get_custom_near_tech({
        customer_uuid: this.value,
      });
      console.log("sad", resp.data._embedded.nearestTechnician);

      this.data = resp.data._embedded.nearestTechnician.map((t) => {
        return {
          ...t,
          position: {
            lat: parseFloat(t.latitude),
            lng: parseFloat(t.longitude),
          },
        };
      });
      let bounds = new google.maps.LatLngBounds(); // eslint-disable-line
      bounds.extend(this.building.position);
      this.data.forEach((t) => {
        bounds.extend({
          lat: t.position.lat,
          lng: t.position.lng,
        });
      });
      (this.$refs.mapping as any).fitBounds(bounds);
    } catch (error) {
      Notif.notif_warning(error.response.data.detail);
    }
  }

  getInfoWindows(detail: positionType) {
    this.infoWindow = {
      position: detail.position,
      name: detail.firstName,
      distance: parseFloat(detail.distance).toFixed(2),
      phone: detail.phone,
      mobile: detail.mobile,
      lastOnline: time_format(detail.lastOnline, 'YYYY-MM-DD hh:mm:ss A'),
      id: "track",
      IsOpen: true,
      options: this.infoWindow.options,
    };
  }

  /* Life-cycle Methods */

  mounted() {
    this.fetchData();
  }
}
